import React from "react"
import { Link } from "gatsby"
import "@fontsource/newsreader"

export default function Papers() {
  return (
    <div class="container overarching-div">
      <div>
        <h2>2024</h2>
        <ul>
          <li className="text-normal">
            <p>
              <b>Bilik, M.</b>, C. Zhan, J. Lin, Y. Yang, H. Lee, S. Das, and J.
              Forlizzi. Privy: Developing Privacy Preserving Products with AI
              Assistance. CMU Human-Computer Interaction Institute REU final
              presentation, 2024. Paper in preparation.
            </p>
          </li>
        </ul>
        {/* <h2>2023</h2>
        <ul>
          <li className="text-normal">
            <p>
              G. Grill and <b>Bilik, M.</b> Complaint Platforms for Workers:
              Examining the Politics of Glassdoor. In The 24th Annual Conference
              of the Association of Internet Researchers, Philadelphia, PA, 2023{" "}
            </p>
          </li>
        </ul> */}
        <h2>2020</h2>
        <ul>
          {/* <li><p>Work presented to NYC DOH (Department of Health) and NYC DOT (Department of Transportation).</p></li> */}
          <li className="text-normal">
            <p>
              <b>Bilik, M.</b>, Teyton, A., Woody, M., Piacentini, L., Norouzi,
              H. and Blake, R. (2020, October). Implementing Q Learning and
              Multimethod Data Analysis to Determine Land Surface Temperature
              and Heat Indexed Routes at the Neighborhood-Scale. Poster
              presented at the American Geophysical Union Fall 2020 Conference,
              San Francisco, CA. Abstract retrieved from
              https://agu.confex.com/agu/fm20/meetingapp.cgi/Paper/754127
            </p>
          </li>
          <li>
            <p className="text-normal">
              Liebowitz, E. Sebastian, C. Yanos, <b>Bilik, M.</b>, Ginchereau, J., Valentine, M., Barclay, K., Montoya, S., Rice, M.,
              Norouzi, H. and Blake, R. Urban Heat Islands and Remote Sensing: Characterizing
              Land Surface Temperature at the Neighborhood Scale. In IGARSS 2020
              - 2020 IEEE International Geoscience and Remote Sensing Symposium,
              page 4407–4409, 2020. Equal authorship
            </p>
          </li>
          <li className="text-normal">
            <p>
              Teyton, A., Woody, M., Piacentini, L., <b>Bilik, M.,</b> Norouzi,
              H. and Blake, R. (2020, October). The Interconnection between the
              Urban Heat Island and Social Factors on Health Outcomes:
              Transforming Heat Wave Experiences into Meaningful Community
              Improvement. Poster presented at the American Geophysical Union
              Fall 2020 Conference, San Francisco, CA. Abstract retrieved from
              https://agu.confex.com/agu/fm20/meetingapp.cgi/Paper/752093
            </p>
          </li>
          <li className="text-normal">
            <p>
              Piacentini, L., Woody, M., Teyton, A., <b>Bilik, M.,</b> Norouzi,
              H. and Blake, R. (2020, October). The Urban Heat Island Effect:
              Capturing Community Engagement in Bedford-Stuyvesant, Brooklyn,
              NY. Poster presented at the American Geophysical Union Fall 2020
              Conference, San Francisco, CA. Abstract retrieved from
              https://agu.confex.com/agu/fm20/meetingapp.cgi/Paper/760446
            </p>
          </li>
          <li className="text-normal">
            <p>
              Woody, M., Teyton, A., Piacentini, L., <b>Bilik, M.,</b> Norouzi,
              H. and Blake, R. (2020, October). Transforming Students into
              Climate Scientists through Urban Heat Island Investigations.
              Poster presented at the American Geophysical Union Fall 2020
              Conference, San Francisco, CA. Abstract retrieved from
              https://agu.confex.com/agu/fm20/meetingapp.cgi/Paper/759197
            </p>
          </li>
        </ul>
        <h2>2019</h2>

        <ul>
          <li className="text-normal">
            <p>
              Liebowitz, A., Sebastian, E., Yanos, C., <b>Bilik, M.</b>,
              Ginchereau, J., Valentine, M., Barclay, K., Montoya, S., Rice, M.,
              Norouzi, H. and Blake, R. (2019, December). Engaging Citizen
              Scientists in Characterizing Urban Heat Island at the Neighborhood
              Scale Using Satellite and Ground Observations. Poster presented at
              the American Geophysical Union Centennial Conference, San
              Francisco, CA. Abstract retrieved from
              https://agu.confex.com/agu/fm19/meetingapp.cgi/Paper/592972
            </p>
          </li>
          {/* <h4>2019 NYC Mayor's Office of Data Analytics</h4>
          <li>
            <p>
              <b>Bilik, M.</b>, Lu, B., (2019, June). Civic Engagement & Data
              Literacy: A Novel Machine Learning Solution. Presented at New York
              City Hall, NYC Open Data Youth Leadership Council.
            </p>
          </li> */}
        </ul>
      </div>
    </div>
  )
}
